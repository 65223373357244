.PlaygroundAttributesContainer {
    padding: 12px 24px;
    background-color: white;
    border-left: 1px solid #e6e6e6;
    height: 100%;
    overflow: auto;
}

.PlaygroundAttributesAttribute {
    margin-bottom: 24px;
}

.PlaygroundAttributesTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 12px 0;
    width: 100%;
    text-align: center;
    font-family: var(--font-family);
}

.PlaygroundAttributesAttributeTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    font-family: var(--font-family);
}

.PlaygroundAttributesAttributeSlider {
    display: flex;
}