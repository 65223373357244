.react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-horizontal-scrolling-menu--inner-wrapper {
    position: relative;
}

.react-horizontal-scrolling-menu--arrow-left {
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 50%;
    z-index: 99;
}

.react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    z-index: 99;
}

.react-horizontal-scrolling-menu--inner-wrapper {
    padding: 0 15px;
}