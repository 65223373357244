.SubscriptionsModal .ant-modal-content {
    background-color: #f5f5f5;
}

.SubscriptionsModalHeader {
    background-color: #f5f5f5;
    padding: 24px 24px 12px 24px;
}

.SubscriptionsModalTitle {
    font-size: 28px;
    font-family: var(--font-family);
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.SubscriptionsModalSubtitle {
    font-size: 18px;
    font-weight: 400;
    font-family: var(--font-family);
}


.SubscriptionsModalWelcomeMessage {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-dark-grey);
}