.ToolExampleContainer {
    width: 300px;
}

.ToolExampleLabel {
    font-size: 18px;
}

.ToolExampleDescription {
    font-family: var(--font-family);
    color: var(--color-dark-grey);
}