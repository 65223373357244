/* src/components/text-input/TextInput.css */

/* Container externo */
.container {
  min-height: 5vh;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
}

/* Container do input principal */
.input-container {
  width: 100%;
  max-width: 48rem; /* Mantém o máximo de 48rem no desktop */
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
}

/* Linha superior: input + botão de enviar */
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

/* O input/textarea em si */
.message-input {
  width: 100%;
  background-color: transparent;
  color: #333;
  /* O padding-right (3rem) reserva o espaço para o botão de enviar */
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  border: none;
  outline: none;
  font-size: 1rem;
  line-height: 1.4;
  resize: none;
  caret-color: transparent; /* Oculta o caret quando não focado */
}

/* Exibe o caret quando o campo estiver focado */
.message-input:focus {
  caret-color: #333;
}

/* Placeholder acinzentado (caso seja utilizado) */
.message-input::placeholder {
  color: #999;
}

/* Estilização da área do placeholder customizado */
.custom-placeholder {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  /* Importante: limita o texto à área do input, respeitando o espaço reservado para o botão */
  right: 3rem;
  z-index: 1;
  pointer-events: none;
  color: #999;
  white-space: pre-wrap; /* Permite que o texto quebre linha */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}

/* Linha inferior de ícones */
.icon-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Botões de ícone */
.icon-button {
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover no botão */
.icon-button:hover {
  background-color: #eee;
}

/* Ícones: tamanho e cor */
.icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #666;
}

/* Melhorias para mobile */
@media (max-width: 768px) {
  .input-container {
    max-width: 100%;
    margin-bottom: 1.5rem;
    padding: 0.75rem;
  }
  
  .container {
    padding: 0.5rem;
  }
  
  .custom-placeholder {
    top: 0.4rem;
    left: 0.4rem;
    /* Mantém o espaço reservado para o botão de enviar */
    right: 3rem;
    font-size: 0.9rem;
  }
}
