.UserDropdownContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.UserDropdown:hover {
    cursor: pointer;
    background-color: var(--color-primary-bg-hover);
    color: var(--color-primary-text-hover);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.UserDropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 18px;
    background-color: var(--color-primary-bg);
    border-radius: 8px;
    height: 42px;
    color: var(--color-primary-text-active);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.UserDropdownItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
}