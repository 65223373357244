.ImageGeneratorPage {
    overflow: auto;
    height: 100%;
    width: 100%;
    position: relative;
}

@media (max-width: 1600px) {
    .ImageGeneratorPage {
        display: flex;
        flex-direction: column;
    }

    .submenu-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .submenu-item {
        font-size: 14px;
    }

    .submenu-icon {
        margin-right: 8px;
    }

    .image-credits-widget-container {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
        max-width: 200px;
    }

    /* Adjust the main content area */
    .ImageGeneratorPage > div:not(.submenu-container) {
        width: 100%;
        padding: 0 20px;
    }

    /* Make the generator, gallery, and community views more compact */
    .generator-container,
    .gallery-container,
    .community-container {
        gap: 15px;
    }

    /* Adjust image sizes in galleries */
    .image-item {
        width: calc(33.33% - 10px);
    }

    /* Make form elements in the generator more compact */
    .generator-form input,
    .generator-form textarea,
    .generator-form button {
        font-size: 14px;
        padding: 8px 12px;
    }
}

@media (max-width: 1200px) {
    .image-item {
        width: calc(50% - 10px);
    }
}

@media (max-width: 768px) {
    .image-item {
        width: 100%;
    }

    .submenu-container {
        overflow-x: auto;
        white-space: nowrap;
    }

    .submenu-item {
        display: inline-block;
        margin-right: 15px;
    }

    /* Manter o widget fixo no canto inferior direito */
    .image-credits-widget-container {
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 1000;
        max-width: 150px;
        margin: 0;
        text-align: right;
    }
}

@media (max-width: 735px) {
    .image-credits-widget-container {
        position: fixed;
        top: 70px; /* Ajuste este valor conforme necessário */
        right: 10px;
        bottom: auto;
        max-width: 120px;
    }
}

@media (max-width: 480px) {
    /* Further adjustments for very small screens */
    .generator-form {
        display: flex;
        flex-direction: column;
    }

    .generator-form input,
    .generator-form textarea,
    .generator-form button {
        width: 100%;
        margin-bottom: 10px;
    }

    /* Manter o widget fixo e compacto */
    .image-credits-widget-container {
        max-width: 120px;
    }
}

@media (max-width: 458px) {
    .image-credits-widget-container {
        position: fixed;
        top: auto;
        bottom: 700px; /* Ajuste este valor conforme necessário */
        right: 10px;
        max-width: 100px;
    }

    .submenu-container {
        padding-bottom: 60px; /* Adicione espaço para o widget de créditos */
    }
}

@media (max-width: 418px) {
    .image-credits-widget-container {
        position: fixed;
        top: auto;
        bottom: 650px; /* Ajuste este valor conforme necessário */
        right: 10px;
        max-width: 100px;
    }

    .submenu-container {
        padding-bottom: 60px; /* Adicione espaço para o widget de créditos */
    }
}
