.ImageEditorCarouselContainer {
    margin: 12px;
    padding: 12px;
    text-align: center;
}

.ImageEditorCarouselImage {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: opacity .15s;
}

.ImageEditorCarouselImage:not(:disabled):hover {
    opacity: 0.7;
    transition: opacity .15s;
    cursor: pointer;
}

.ImageEditorCarouselImageContainer {
    position: relative;
    padding: 4px;
}