.MainHeader {
    background-color: white !important;
    border-bottom: var(--color-border) 1px solid;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0;
    position: sticky !important;
    top: 0 !important;
}

.MainHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 12px;
    height: 100%;
}

.MainHeaderLeftContainer {
    margin-right: auto;
}

.MainHeaderLogo {
    height: 24px;
}

.MainHeaderRightContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.MainHeaderUserSection {
    display: inline-block;
}

.MainHeaderUserButton {
    border-radius: 12px !important;
    font-family: var(--font-family);
    font-size: 14px;
    height: 32px;
    background-color: black;
    color: white;
}

.HeaderIcon {
    margin-right: 6px;
}

.HeaderButton {
    display: flex;
    align-items: center;
}

.HeaderButtonIcon {
    display: inline-block;
    font-size: 32px;
    color: var(--header-icon-color);
    margin-left: 12px;
}

.MainHeaderSubscriptionLabelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 16px;
}

.MainHeaderSubscriptionLabelTrialBadge {
    background-image: linear-gradient(135deg, #62afd9 -10.67%, #5437d4 42.73%, #bf4cf9 110%);
    height: 24px;
    border-radius: 24px;
    padding: 0 12px;
    color: white;
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MainHeaderSubscriptionLabel {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

/* Media Query para mobile: garante altura fixa e que o container não ultrapasse o header */
@media (max-width: 1200px) {
    .MainHeader {
        margin-left: 0 !important;
        padding-left: 0 !important;
        height: 48px !important;  /* Altura fixa para o header */
    }
    .MainHeaderContainer {
        padding-inline: 8px;
        height: 100%;  /* Ocupa 100% da altura do header */
        align-items: center;
    }
}
