.WelcomeModal {
    padding: 24px;
    position: relative;
    overflow: auto;
}

.WelcomeModalHeader {
    padding: 24px 0 0 24px;
}

.WelcomeModalHeaderMobile {
    padding: 6px 0 0 6px;
}

.WelcomeModalTitle {
    font-size: 28px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.WelcomeModalTitleMobile {
    font-size: 20px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.WelcomeModalSubtitle {
    font-size: 18px;
    font-weight: 400;
}

.WelcomeModalContent {}

.WelcomeModalParagraph {
    font-size: 16px;
    color: var(--color-dark-grey);
}

.WelcomeModalParagraphMobile {
    font-size: 14px;
    color: var(--color-dark-grey);
}

.WelcomeModalParagraphTitle {
    font-size: 24px;
}

.WelcomeModalParagraphTitleMobile {
    font-size: 18px;
}

.WelcomeModalCTAMessage {
    margin-top: 24px;
    font-size: 22px;
    width: calc(100% - 200px);
}

.WelcomeModalCTAMessageMobile {
    margin-top: 24px;
    font-size: 18px;
}

.WelcomeModalRobotImage {
    position: absolute;
    right: 0;
    bottom: 0;
}

.WelcomeModalContentHighlight {
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.WelcomeModalParagraphCoupon {
    text-align: center;
    font-size: 32px;
    margin-top: 24px;
}

@media (max-height: 700px) {
    .WelcomeModalHeader {
        padding: 12px 0 0 24px;
    }

    .WelcomeModalTitle {
        font-size: 24px;
    }

    .WelcomeModalParagraphTitle {
        font-size: 18px;
    }

    .WelcomeModalParagraph {
        font-size: 15px;
    }

    .WelcomeModalParagraphCoupon {
        font-size: 24px;
        margin-top: 12px;
    }

    .WelcomeModalCTAMessage {
        margin-top: 12px;
        font-size: 18px;
    }
}