@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Outfit';
  src: local("Outfit"), url(./fonts/Outfit-Regular.ttf) format("truetype");
}

:root {
  --background-color: #4f647a;
  --chat-background-color: #ffffff;
  --icon-color: #7b7f88;
  --icon-hover-color: #333c46;
  --accent-color: #E2E2E2;
  --font-primary-color: #282c34;

  --font-family: 'Outfit', sans-serif !important;

  --menu-button-background-color: #ececec;
  --menu-button-hover-background-color: #c5c5c5;

  --header-icon-color: #717972;

  --color-white: #ffffff;
  --color-dark: #1d1c1d;
  --color-dark-grey: #6f6f6f;
  --color-light-grey: #dadada;
  --color-purple: #724CF9;
  --color-purple-2: #661AA1;
  --color-green: #2ED16F;
  --color-pink: #FF0D55;
  --color-pink-2: #E52B67;
  --color-orange: #FF7A00;

  --color-border: #ececec;

  --color-primary: #724CF9;
  --color-primary-hover: #9a78ff;
  --color-primary-active: #5437d4;
  --color-primary-bg: #f6f0ff;
  --color-primary-bg-hover: #f9f6ff;
  --color-primary-bg-active: #dac9ff;
  --color-primary-text: #724cf9;
  --color-primary-text-hover: #9a78ff;
  --color-primary-text-active: #5437d4;
  --color-primary-border: #dac9ff;
  --color-primary-border-hover: #bba1ff;

  --color-secondary: #2ED16F;

  --default-box-shadow: rgba(0, 0, 0, 0.05) 0 3px 12px;
  --default-linear-gradient: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);


  --color-error: #882424;
  --color-subtitle: #b6b6b6;

  --color-background-main: #011528;
}

@media (max-width: 768px) {
  .ant-tooltip {
    display: none;
  }
}

span, label, textarea, input {
    font-family: var(--font-family) !important;
}

.ant-input-show-count-suffix, .ant-input-data-count {
  font-size: 12px;
}

.ant-form-item-explain-error {
  font-family: var(--font-family);
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

/* Set the background color of the scroll bar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Set the color of the scroll bar thumb */
::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
  border-radius: 25px;
}

.ant-drawer-title {
  color: var(--color-pink-2) !important;
  font-size: 24px !important;
}

.p-tabview-panels {
  padding: 0 !important;
}

.ql-editor {
    font-family: var(--font-family) !important;
}

.PremiumTitle {
  font-weight: 600;
  background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: var(--font-family);
}