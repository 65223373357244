.ToolsContainer {
    padding: 24px 36px;
    overflow: auto;
}

.ToolsContainer::-webkit-scrollbar {
    width: 6px;
    height: 8px;
}

/* Set the background color of the scroll bar */
.ToolsContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Set the color of the scroll bar thumb */
.ToolsContainer::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
    border-radius: 25px;
}

@media (max-width: 768px) {
    .ToolsContainer {
        padding: 0 36px 24px 36px;
    }
}