.TaxInformationModalTitle {
    font-size: 28px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.TaxInformationModalSubtitle {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-dark-grey);
}