/* src/components/chat/ChatHistoryItem.css */

/* Container principal do item de histórico de chat */
.ChatHistoryItem {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 8px 12px;
    transition: background-color 0.3s ease;
    background-color: transparent;
}

.ChatHistoryItemDropdownButtonIcon,
.ChatHistoryItemDropdownButtonIcon * {
  color: #000 !important;
}

.ChatHistoryItem:hover {
    background-color: #e0d0ff !important; /* Exemplo de roxo */
    cursor: pointer;
  }

/* Estilo quando o item está desabilitado */
.ChatHistoryItemDisabled {
    opacity: 0.6;
    pointer-events: none;
}

/* Container para o título do chat */
.ChatHistoryItemTitleContainer {
    flex: 1 1 auto;
    min-width: 0;
    margin-left: 12px;
    display: flex;
    align-items: center;
}

/* Título do chat */
.ChatHistoryItemTitle {
    flex: 1 1 auto;
    text-align: left;
    font-family: var(--font-family);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #ffffff;
}

/* Input para editar o título do chat */
.ChatHistoryItemTitleInput {
    flex: 1 1 auto;
    text-align: left;
    font-family: var(--font-family);
    padding: 4px 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: #2d2d2d;
    color: #ffffff;
}

/* Container para os botões de ação */
.ChatHistoryItemActionSection {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

/* Botões de ação (confirmar, cancelar) */
.ChatHistoryItemActionButton {
    color: var(--icon-color);
    margin-left: 8px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.ChatHistoryItemActionButton:hover {
    color: var(--icon-hover-color);
}

/* Item selecionado */
.ChatHistoryItemSelected {
    background-color: var(--accent-color);
}

/* Botão de dropdown */
.ChatHistoryItemDropdownButton {
    color: var(--icon-color);
    margin-left: 8px;
    display: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

.ChatHistoryItem:hover > .ChatHistoryItemDropdownButton {
    display: block;
}

.ChatHistoryItemDropdownButton:hover {
    color: var(--icon-hover-color);
}

/* Avatar do chat */
.ChatHistoryItemAvatar {
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    height: 40px;
    width: 40px;
    background-color: #e7ebf3;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    color: white;
    flex-shrink: 0;
}

/* Estilos quando o item está colapsado */
.ChatHistoryItem.collapsed {
    flex-direction: column;
    align-items: center;
    padding: 8px 0;
}

.ChatHistoryItem.collapsed .ChatHistoryItemAvatar {
    margin: 0 auto;
}

.ChatHistoryItem.collapsed .ChatHistoryItemTitleContainer {
    display: none;
}

.ChatHistoryItem.collapsed .ChatHistoryItemActionSection {
    display: none;
}

/* Estilos responsivos */
@media (max-width: 748px) {
    .ChatHistoryItem {
        padding: 6px 8px;
    }
    .ChatHistoryItemAvatar {
        height: 32px;
        width: 32px;
        font-size: 18px;
    }

    .ChatHistoryItemTitle {
        font-size: 14px;
    }

    .ChatHistoryItemTitleInput {
        font-size: 14px;
    }

    .ChatHistoryItemActionButton,
    .ChatHistoryItemDropdownButton {
        font-size: 14px;
    }
}
