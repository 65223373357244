.HeaderTitle {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: bold;
    color: var(--color-dark);
    width: calc(100% - 60px);
    margin: 24px 0;
}

.HeaderSubtitle {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--color-dark-grey);
    margin: 24px 0;
}

.HeaderBadge {
    color: var(--color-dark-grey) !important;
    font-family: var(--font-family);
}

.HeaderLogoContainer {
    display: grid;
    justify-items: center;
    align-items: center;
}

.HeaderLogo {
    height: 42px;
    margin-bottom: 48px;

}