/* src/components/chat/ChatRenderer.css */

.Chat {
    position: relative;
    padding: 0 48px 24px 48px;
    background-position: 50% 0;
    background-color: #f5f5f5;
    background-size: 250px 250px;
    background-repeat: repeat;
    height: calc(100% - 80px); /* devido ao seletor de modelo de geração */
    margin-bottom: 24px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

/* Estilo para mensagens de artefato */
.artifact-message {
    position: relative;
    padding: 10px;
    background-color: #2d2d2d; /* Fundo preto */
    border-radius: 8px;
    margin-bottom: 16px;
    color: #f5f5f5; /* Texto claro */
}

/* Estilo para o botão "Preview" */
.artifact-message .ant-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.ChatHeader {
    width: 100%;
    border-bottom: #dedede 1px solid;
    padding-bottom: 12px;
    position: relative;
}

.ChatTitle {
    color: #282c34;
    font-size: 1.5rem;
    width: 100%;
}

.ChatSpecialtyContainerSelect {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.ChatSpecialtySelect {
    width: 100%;
    font-family: var(--font-family);
}

.ChatSpecialtyInfoIcon {
    font-size: 1rem;
    color: var(--icon-color);
    margin-right: 8px;
}

.ChatSpecialtyInfoIcon:hover {
    cursor: pointer;
    color: var(--icon-hover-color);
}

.ChatContainer {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    /* Remover a altura fixa para permitir que o flexbox gerencie a altura */
}

/* Personalização da barra de rolagem para navegadores WebKit */
.ChatContainer::-webkit-scrollbar {
    width: 8px; /* Aumentamos de 6px para 8px */
}

.ChatContainer::-webkit-scrollbar-track {
    background: transparent;
}

.ChatContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px; /* Aumentamos o border-radius para 4px */
}

.ChatContainer::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

/* Personalização da barra de rolagem para Firefox */
.ChatContainer {
    scrollbar-width: auto; /* Alterado de 'thin' para 'auto' */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Estilos adicionais */
.ant-select,
.ant-select-item-option-content,
.ant-select-selection-search,
.ant-select-selector,
.ant-select-selection-search-input {
    font-family: var(--font-family) !important;
}

.ChatHistoryContainer::-webkit-scrollbar {
    width: 10px;
    display: none;
}

.ChatHistoryContainer::-webkit-scrollbar-thumb {
    background-color: #b6b6d7;
    display: none;
}

.demo-bg {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}

.disabled {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.ChatInput {
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: center;
}

.ChatInputMobile {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 12px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 24px 24px 24px;
}

.ChatInputSpecialtyButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
}

.ChatInputSpecialtyButton {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ChatInputSpecialtyIcon {
    color: var(--color-dark-grey);
    cursor: pointer;
    font-size: 18px;
    margin-top: 4px;
}

.ChatSpecialtySelect .ant-select-selector {
    height: 52px !important;
}

.ChatInputSpecialtyIcon:hover {
    color: var(--color-purple);
    transform: scale(1.25);
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
}

.load-more-messages-button {
    width: 100%;
    margin: 16px 0;
    padding: 12px;
    background-color: #f0f2f5;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.load-more-messages-button:hover {
    background-color: #e6e8eb;
    border-color: #40a9ff;
}

.loading-old-messages {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 8px;
    margin: 16px 0;
}

.loading-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.loading-spinner-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

@media (max-width: 768px) {
    .Chat {
        padding: 8px 12px 32px 12px;
    }
}
