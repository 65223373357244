.CreatorToolIntroTab {
    padding: 12px 24px;
}

.CreatorToolIntroTabPromptLabel {
    margin: 6px 0;
    font-family: var(--font-family);
    font-size: 16px;
}

.CreatorToolIntroTabFieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}