.InputProgressBar {
    display: flex !important;
}

.InputProgressBar .ant-progress-outer {
    flex: 1;
}

.InputProgressBar .ant-progress-text {
    align-self: flex-start;
    width: auto;
    margin-top: 6px;
}

.InputProgressBar .ant-progress-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    text-align: right;
}