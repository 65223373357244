.ImageEditor {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ImageEditorUploadedImage {
    box-shadow: var(--default-box-shadow);
    border-radius: 12px;
}

.ImageEditorCanvas {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ImageEditorEmptyCanvas {
    background-color: #eaeaea;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.ImageEditorGenerateButton {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
}

.ImageEditorPromptInputTitle {
    font-size: 18px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: var(--font-family);
    width: 60%;
    margin-bottom: 6px;
}

.ImageEditorGenerator {
    display: grid;
    align-items: center;
    flex-grow: 1;
}

.ImageEditorFloatingButtons {
    position: absolute;
    right: 18px;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ImageEditorCanvasContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ImageEditorCanvasImage {
    position: relative;
}

.ImageEditorCanvasImageMenu {
    visibility: hidden;
}

.ImageEditorCanvasImage:hover .ImageEditorCanvasImageMenu {
    visibility: visible;
}

.ImageEditorLabelWarning {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--color-dark-grey);
    margin: 6px 0;
    text-align: center;
    width: 100%;
}