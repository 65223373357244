.SignUpFooterTermsAndConditions {
    color: var(--color-dark-grey) !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
}

.amplify-link {
    color: var(--color-dark-grey);
    font-family: var(--font-family);
}

.SignUpFooterLink {
    color: var(--color-green);
}

.SignUpFooterLink:hover {
    color: var(--color-purple);
}