.PromptInputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .PromptInputContainer {
    width: 100%;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .PromptInput {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    padding-bottom: 24px; /* Added to make space for the character count */
    font-size: 16px;
    line-height: 1.5;
    resize: none;
    transition: all 0.3s ease;
    position: relative; /* Added to contain the absolute positioned character count */
  }
  
  .PromptInput:focus {
    border-color: #8b5cf6;
    box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.2);
    outline: none;
  }
  
  .PromptInputFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px; /* Increased from 16px to 24px */
  }
  
  .PublicSwitchContainer {
    display: flex;
    align-items: center;
  }
  
  .PublicSwitchLabel {
    margin-left: 8px;
    font-size: 14px;
    color: #4b5563;
  }
  
  .PromptGenerateButton {
    background-color: #8b5cf6;
    border-color: #8b5cf6;
    color: white;
    font-weight: 600;
    padding: 8px 24px;
    height: auto;
    transition: all 0.3s ease;
  }
  
  .PromptGenerateButton:hover:not(:disabled) {
    background-color: #7c3aed;
    border-color: #7c3aed;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.25);
  }
  
  .PromptGenerateButton:active:not(:disabled) {
    transform: translateY(0);
  }
  
  /* Estilização para o contador de caracteres */
  .ant-input-show-count::after {
    color: #6b7280;
    font-size: 12px;
  }
  
  .CharacterCount {
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-size: 12px;
    color: #6b7280;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .PromptInputContainer {
      padding: 16px;
    }
  
    .PromptInput {
      font-size: 14px;
    }
  
    .PromptInputFooter {
      flex-direction: column;
      align-items: stretch;
    }
  
    .PublicSwitchContainer {
      margin-bottom: 16px;
    }
  
    .PromptGenerateButton {
      width: 100%;
    }
  }
  
  /* Acessibilidade */
  .PromptInput:focus-visible,
  .ant-switch:focus-visible,
  .PromptGenerateButton:focus-visible {
    outline: 3px solid #8b5cf6;
    outline-offset: 2px;
  }