.CreatorToolEditFieldWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.CreatorToolEditFieldWrapper .p-chips .p-inputtext {
    width: 100%;
}

.CreatorToolEditFieldLabel {
    margin: 6px 0;
    font-family: var(--font-family);
    font-size: 16px;
}

.CreatorToolEditFieldSliderWrapper {
    display: flex;
    width: 100%;
}

.CreatorToolEditFieldSliderCounter {
    font-family: var(--font-family);
    font-size: 16px;
    margin: 0 16px;
    display: flex;
    align-items: center;
}