.PremiumButton {
    background-image: linear-gradient(135deg, #62afd9 -10.67%, #5437d4 42.73%, #bf4cf9 110%);
    border: unset;
    transition: opacity .15s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PremiumButton:not(:disabled):hover {
    opacity: 0.85;
    transition: opacity .15s;
}

.PremiumButton:disabled {
    background: var(--color-light-grey);
}