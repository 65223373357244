.CreatorToolFieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.CreatorToolFieldLabel {
    margin: 6px 0;
    font-family: var(--font-family);
    font-size: 16px;
}

#CreatorToolIconUploadWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

#CreatorToolIconUploadAvatar {
    margin-right: 24px;
}

.CreatorToolOutputsSliderWrapper {
    display: flex;
    width: 100%;
}

.CreatorToolOutputsSliderCounter {
    font-family: var(--font-family);
    font-size: 16px;
    margin: 0 16px;
    display: flex;
    align-items: center;
}

.CreatorToolArtifactGenderCheckboxWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
}

.CreatorToolFieldContainer .p-chips .p-inputtext {
    min-width: 450px;
}