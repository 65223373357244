#CreatorToolFieldsTabAddButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

#CreatorToolFieldsTabAddButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 6px 0;
}

.CreatorToolFieldsTabFieldContainer {
    padding: 0 24px;
    overflow: auto;
}

.CreatorToolFieldsTabContainer {
    overflow: auto;
    padding: 0 4px;
}