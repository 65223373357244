.ImageMenuOptions {
    position: absolute;
    top: 12px;
    right: 12px;
}

.ImageMenuOptionsItem {
    min-width: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ImageMenuOptionsItemLabel {
    left: 24px;
    color: var(--color-dark);
}

.ImageMenuOptionsItemIcon {
    color: var(--color-dark);
    margin-right: 8px;
}