.PostRegistrationContainer {
    height: calc(100vh - 52px);
}

.PostRegistrationScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.PostRegistrationScreenTitleContainer {
    margin-bottom: 24px;
}

.PostRegistrationScreenTitle {
    font-size: 32px;
    font-weight: bold;
    font-family: var(--font-family);
    color: var(--color-dark);
}

.PostRegistrationScreenSubtitle {
    font-size: 16px;
    font-family: var(--font-family);
    color: var(--color-dark-grey);
}

.PostRegistrationFormFieldContainer {
    margin: 12px 0;
    width: 100%;
    display: grid;
    gap: 6px;
}

.PostRegistrationFormFieldDividedContainer {
    margin: 12px 0;
    width: 100%;
}

.PostRegistrationFormFieldTitle {
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: bold;
    color: var(--color-dark);
}

.PostRegistrationFormFieldSubtitle {
    font-size: 16px;
    font-family: var(--font-family);
    color: var(--color-dark);
}

.PostRegistrationFormSingleButton {
    margin: 24px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.PostRegistrationFormDoubleButton {
    margin: 24px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.PostRegistrationProgressBar {
    margin-inline-end: 0 !important;
    margin-bottom: 0 !important;
}

.PostRegistrationSelectableBox {
    border: 1px solid var(--color-primary-border);
    border-radius: 16px;
    padding: 12px;
    cursor: pointer;
    height: 100px;
    min-width: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
}

.PostRegistrationSelectableBox:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.2s ease-in-out;
}

.PostRegistrationSelectableBoxSelected {
    border: 2px solid var(--color-primary);
    transition: border 0.2s ease-in-out;
}

.PostRegistrationSelectableBoxLabel {
    font-size: 14px;
    margin-top: 6px;
    color: var(--color-primary-border);
}

.PostRegistrationSelectableBoxLabelSelected {
    color: var(--color-purple);
    font-weight: bold;
}

.PostRegistrationSelectableBoxIcon {
    font-size: 28px;
    color: var(--color-primary-border);
}

.PostRegistrationSelectableBox:hover .PostRegistrationSelectableBoxIcon {
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.25) scaleY(1.25);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.PostRegistrationSelectableBox .PostRegistrationSelectableBoxIcon {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.PostRegistrationSelectableBoxIconSelected {
    font-size: 28px;
    color: var(--color-purple);
}

.PostRegistrationSelectableBoxContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 24px; /* optional gap between grid items */
    width: 100%;
}

.p-progress-spinner-circle {
    stroke: var(--color-purple) !important;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite !important;
}

.PostRegistrationFormFieldContainer .p-inputtext:focus {
    box-shadow: unset !important;
}

.PostRegistrationFormFieldDividedContainer .ant-col .p-inputtext {
    padding: 10px !important;
}

.PostRegistrationContentContainer {
    height: calc(100% - 24px);
    margin-top: 24px;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .PostRegistrationScreenTitle {
        font-size: 26px;
    }

    .PostRegistrationFormFieldTitle {
        font-size: 14px;
    }

    .PostRegistrationFormFieldContainer, .PostRegistrationFormFieldDividedContainer {
        margin: 6px 0;
    }

    .PostRegistrationFormSingleButton, .PostRegistrationFormDoubleButton {
        margin: 12px 0;
    }

    .PostRegistrationScreen {
        padding: 0 12px;
    }

    .PostRegistrationSelectableBoxContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 12px; /* optional gap between grid items */
        width: 100%;
    }

    .PostRegistrationSelectableBox {
        border: 1px solid var(--color-primary-border);
        border-radius: 16px;
        padding: 6px;
        cursor: pointer;
        height: 80px;
        min-width: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}