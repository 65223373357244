.ImageCreditsWidget {
    margin: 0;
}

.ImageCreditsWidgetContainer {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ImageCreditsWidgetPopoverContent {
    padding: 6px;
    display: flex;
    flex-direction: column;
    width: 240px;
}

.ImageCreditsWidgetTrialPopoverText {
    color: var(--color-dark-grey);
    font-size: 12px;
    margin-top: 4px;
    font-family: var(--font-family);
}

.image-credits-widget-container {
    margin: 0;
}

/* Estilos responsivos */
@media (max-width: 735px) {
    .ImageCreditsWidgetContainer {
        padding: 2px 4px;
        font-size: 10px;
    }

    .ImageCreditsWidget .ant-statistic-title {
        font-size: 8px;
    }

    .ImageCreditsWidget .ant-statistic-content {
        font-size: 10px;
    }

    .ImageCreditsWidget .ant-btn {
        font-size: 8px;
        padding: 1px 3px;
        height: auto;
    }
}

@media (max-width: 768px) {
    .ImageCreditsWidgetContainer {
        padding: 3px 6px;
    }

    .ImageCreditsWidget .ant-statistic-title {
        font-size: 10px;
    }

    .ImageCreditsWidget .ant-statistic-content {
        font-size: 12px;
    }

    .ImageCreditsWidget .ant-btn {
        font-size: 10px;
        padding: 2px 6px;
        height: auto;
    }
}

@media (max-width: 480px) {
    .ImageCreditsWidgetContainer {
        padding: 2px 4px;
    }

    .ImageCreditsWidget .ant-statistic-title {
        font-size: 12px;
    }

    .ImageCreditsWidget .ant-statistic-content {
        font-size: 12px;
    }

    .ImageCreditsWidget .ant-btn {
        font-size: 9px;
        padding: 1px 4px;
    }

    .ImageCreditsWidgetPopoverContent {
        width: 200px;
    }

    .ImageCreditsWidgetTrialPopoverText {
        font-size: 10px;
    }
}

