:root {
  --card-max-width: 330px;
  --card-height: 215px;
  --card-padding: 16px;
  --card-border-radius: 12px;
  --badge-border-radius: 12px;
  --badge-padding: 0 8px;
  --badge-gap: 4px;
  --badge-top: 16px;
  --badge-right: 16px;
  --pin-icon-size: 14px;
  --pin-icon-bottom: 12px;
  --pin-icon-left: 12px;
  --title-font-size: 16px;
  --description-font-size: 14px;
  --transition-duration: 0.2s;
}

.ToolCard {
  max-width: var(--card-max-width);
  height: var(--card-height);
  font-family: var(--font-family);
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all var(--transition-duration) ease-in-out;
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  position: relative;
  overflow: hidden;
}

.ToolCard:hover {
  border-color: var(--color-primary-border-hover);
  cursor: pointer;
  background-color: var(--color-primary-bg-hover);
}

.ToolCard:hover .ToolCardTitle,
.ToolCard:hover .ToolCardDescription {
  color: var(--color-primary-text-hover);
}

.ToolCard .ant-card-body {
  padding: var(--card-padding) !important;
}

.ToolCard .ant-card-meta-title {
  margin-bottom: 2px !important;
}

.Badge {
  border-radius: var(--badge-border-radius);
  display: inline-flex;
  gap: var(--badge-gap);
  align-items: center;
  padding: var(--badge-padding);
  color: white;
  font-family: var(--font-family);
  font-size: 0.875rem;
}

.BadgeNew {
  background-color: var(--color-purple);
}

.BadgePopular {
  background-color: var(--color-green);
}

.BadgeContainer {
  position: absolute;
  top: var(--badge-top);
  right: var(--badge-right);
}

.BadgeIcon {
  font-size: 0.75rem;
}

.ToolCardPin {
  position: absolute;
  bottom: var(--pin-icon-bottom);
  left: var(--pin-icon-left);
  color: var(--color-purple);
  font-size: var(--pin-icon-size);
  transition: all var(--transition-duration) ease-in-out;
}

.ToolCardPin:hover {
  color: var(--color-primary-text-hover);
  transform: scale(1.25);
  cursor: pointer;
}

.ToolCardPinActive {
  color: var(--color-primary-text);
}

.ToolCardTitle {
  margin: 6px 0;
  font-size: var(--title-font-size);
  font-weight: bold;
  color: var(--color-dark);
  transition: color var(--transition-duration) ease-in-out;
}

.ToolCardDescription {
  font-size: var(--description-font-size);
  color: var(--color-subtitle);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color var(--transition-duration) ease-in-out;
}

.ToolCard.disabled {
  background-color: #f5f5f500;
  border-color: #d9d9d9;
  color: #00000040;
  cursor: not-allowed;
}

.ToolCard.disabled .ToolCardTitle {
  color: #00000040;
}

.ToolCard.disabled:hover {
  background-color: #f5f5f500;
  border-color: #d9d9d9;
}

@media (max-width: 768px) {
  .ToolCard {
    max-width: 100%;
    height: auto;
  }
}
