.ToolsCategory {
    margin-bottom: 24px;
}

.ToolsCategoryLabel {
    font-size: 18px;
    font-weight: bold;
    margin-left: 26px;
    color: var(--color-purple);
}

.ToolsCategoryTitleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.ToolsCategoryTitleBadge {
    float: right;
    margin-left: 8px;
}

.ToolsCategoryCardRow {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, max-content));
    grid-gap: 24px; /* optional gap between grid items */
    margin-bottom: 8px;
}