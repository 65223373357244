.ResultCard {
    font-family: var(--font-family);
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}

.ResultCard:hover {
    border: 1px solid var(--color-primary-border-hover);
    background-color: var(--color-primary-bg-hover);
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}

.ResultCardContent {
    padding: 0 24px;
}

.ResultCardTitle {
    padding: 0;
    font-weight: bold;
    color: var(--color-primary-text);
    margin-bottom: 8px;
}

.ResultCardTitleField {
    padding: 0;
    font-weight: bold;
    color: var(--color-dark-grey);
    margin-bottom: 8px;
}

.ResultCardContentField {
    padding: 0;
    color: var(--color-dark-grey);
    min-height: auto;
}

.ResultCardIconTray {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    color: var(--color-light-grey);
    margin-top: 8px;
}

.ResultCardIconTrayIcon {
    transition-duration: .2s;
}

.ResultCardIconTrayIcon:hover {
    color: var(--color-primary);
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.25) scaleY(1.25);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s;
    cursor: pointer;
}

.ResultCardSavedIcon {
    color: var(--color-primary);
}

.ToolResultsContainer ul, li {
    list-style: none;
}

.ant-tooltip-inner {
    font-family: var(--font-family) !important;
}

/*li svg {*/
/*    cursor: grab;*/
/*}*/

.ant-rate {
    font-size: 14px !important;
}

.ant-rate-star {
    color: var(--color-primary) !important;
    margin-inline-end: 2px !important;
}

.anticon-star:hover {
    color: var(--color-primary);
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.25) scaleY(1.25);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s;
}

.ant-message-custom-content {
    font-family: var(--font-family) !important;
}

.ResultCard .ant-card-body h1 {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-pink-2);
}
.ResultCard .ant-card-body h2 {
    font-size: 16px;
    color: var(--color-pink-2);
}
.ResultCard .ant-card-body h3 {
    font-size: 15px;
    color: var(--color-pink-2);
}
.ResultCard .ant-card-body h4 {
    font-size: 15px;
    color: var(--color-pink-2);
}
.ResultCard .ant-card-body h5 {
    font-size: 14px;
    color: var(--color-pink-2);
}