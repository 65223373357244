.SignInFooterResetPassword {
  color: var(--color-dark-grey) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
}

.amplify-link {
    color: var(--color-dark-grey);
    font-family: var(--font-family);
}

.amplify-link:hover {
    font-family: var(--font-family);
    color: var(--color-purple);
}

.SignInFooterLink {
    color: var(--color-green) !important;
}

.SignInFooterLink:hover {
    color: var(--color-purple) !important;
}