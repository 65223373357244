.ImageEditorSidebar {
    padding: 36px;
}

.ImageEditorSidebarButton {
    margin: 6px 0;
}

.ImageEditorSidebarTitle, .ImageEditorSidebarPopoverTitle, .ImageEditorSidebarEditionModelDescriptionTitle {
    font-size: 18px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: var(--font-family);
}

.ImageEditorSidebarSelectableOptionContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 8px; /* optional gap between grid items */
    width: 100%;
}

.ImageEditorSidebarSelectableOption {
    border-radius: 16px;
    cursor: pointer;
    height: 100px;
    min-width: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
    border: var(--color-primary) 1px solid;
    padding: 12px;
    text-align: center;
}

.ImageEditorSidebarSelectableOptionIcon {
    font-size: 28px;
    color: var(--color-primary);
}

.ImageEditorSidebarSelectableOptionLabel {
    font-size: 14px;
    color: var(--color-primary);
}

.ImageEditorSidebarSelectableOption:hover .ImageEditorSidebarSelectableOptionIcon {
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.25) scaleY(1.25);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.ImageEditorSidebarSelectableOption .ImageEditorSidebarSelectableOptionIcon {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.ImageEditorSidebarButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
}

.ImageEditorSidebarOptionButton {
    text-align: left;
    color: var(--color-dark);
}

.ImageEditorSidebarOptionButtonSelected {
    justify-content: flex-start;
    color: white;
}

.ImageEditorSidebarOptionButtonIcon {
}

.ImageEditorSidebarPopover {
    padding: 12px;
    width: 380px;
}

.ImageEditorSidebarPopoverText {
    margin: 8px 0;
}

.ImageEditorSidebarPopoverGif {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.ImageEditorSidebarEditionModelDescription {
    margin-bottom: 12px;
}

.ImageEditorSidebarEditionModelDescriptionContent {
    margin-top: 12px;
    font-size: 15px;
    color: var(--color-dark);
}