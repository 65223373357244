.SkipTrialConfirmModalParagraph {
    margin: 6px 0;
    display: inline-block;
}

.SkipTrialConfirmModalConfirmButton {
    margin-left: 12px;
}

.SkipTrialConfirmModalConfirmButton span .PremiumButton {
    display: inline-block;
}