.SubscriptionPlansBillingPeriodSelector {
    border-radius: 25px !important;
    box-shadow: var(--default-box-shadow);
    background: white;
    z-index: 99;
}

.SubscriptionPlansBillingPeriodSelector .p-button {
    border-radius: 25px !important;
    border: none !important;
}

.SubscriptionPlansBillingPeriodSelector .p-button:focus {
    box-shadow: unset !important;
}

.SubscriptionPlansBillingPeriodSelector .p-button.p-highlight {
    z-index: 99;
    background-image: linear-gradient(135deg, #62afd9 -10.67%, #5437d4 42.73%, #bf4cf9 110%) !important;
}

.SubscriptionPlansBillingPeriodSelectorArrow {
    width: 32px;
    transform: rotate(260deg);
    z-index: 10;
    position: absolute;
}