.CreatorToolPreview {
}

.CreatorToolPreviewLabel {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-dark-grey);
    text-align: center;
    font-family: var(--font-family);
    margin-top: 12px;
}

.CreatorToolPreviewHeaderContainer {
    padding: 0 24px 12px 24px;
}

.CreatorToolPreviewToolNameContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 12px;
}

.CreatorToolPreviewToolName {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-dark);
    text-align: center;
    font-family: var(--font-family);
}

.CreatorToolPreviewToolDescription {
    font-size: 16px;
    color: var(--color-dark-grey);
    text-align: left;
    font-family: var(--font-family);
    margin-left: 12px;
}