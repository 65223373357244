.CreatorToolFieldsListItem {
    padding: 6px 12px;
    margin: 3px 0;
    border-radius: 10px;
    background-color: var(--color-primary-bg);
    color: var(--color-primary-text);
    border: var(--color-primary-border) 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.CreatorToolFieldsListItem:hover {
    cursor: pointer;
    background-color: var(--color-primary-bg-hover);
    color: var(--color-primary-text-hover);
}

.CreatorToolFieldsListItemSelected {
    background-color: var(--color-primary-bg-active);
    color: var(--color-primary-text-active);
}

.CreatorToolFieldsListItemLabel {
    font-family: var(--font-family);
    font-size: 16px;
    text-overflow: ellipsis;
}

.CreatorToolFieldsListItemTypeLabel {
    font-family: var(--font-family);
    font-size: 12px;
    text-overflow: ellipsis;
}

.CreatorToolFieldsList {
    padding-inline-start: 0 !important;
}

.CreatorTooFieldListItemDragIcon {
    font-size: 18px;
    font-weight: bolder;
    margin-right: 8px;
}

.CreatorTooFieldListItemDragIcon:hover {
    cursor: grab;
}

.CreatorToolFieldsListItemRemoveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    margin-left: 3px;
}