.PastDueSubscriptionModalTitle {
    font-size: 20px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.PastDueSubscriptionModalSubtitle {
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    color: var(--color-dark-grey);
    margin: 2px 0;
}

.PastDueSubscriptionModalContent {
    margin-bottom: 16px;
}