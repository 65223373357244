.ChatHistoryMobileItem {
    margin-right: 16px;
    display: inline-block;
    cursor: pointer;
}

.ChatHistoryMobileItemLabel {
    margin-top: 6px;
    height: 28px;
    width: 64px;
    text-align: center;

    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;


    font-size: 12px;
    line-height: 1.2;
    font-family: var(--font-family);
    color: var(--color-dark-grey);

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
    cursor: pointer;
}