/* src/components/text-input/TypewriterPlaceholder.css */

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');

.typewriter-placeholder {
  font-family: 'Outfit', sans-serif;
  font-style: italic;
  font-size: 1rem;
  color: #555;                /* cor do texto */
  pointer-events: none;
  user-select: none;
  /* Permite que o texto quebre linhas conforme necessário */
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  /* Animação suave de aparição */
  animation: fadeIn 0.5s ease-out;
}

.cursor {
  display: inline-block;
  background-color: #555;     /* mesma cor do texto para consistência */
  width: 2px;                 /* um pouco mais largo para melhor visibilidade */
  height: 1em;                /* altura igual à linha de texto */
  margin-left: 4px;
  animation: blink 1s step-end infinite;
}

/* Animação de blink usando opacidade */
@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.01%, 100% {
    opacity: 0;
  }
}

/* Animação de fade-in para o texto */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
