.CreatorToolPromptTab {
    padding: 12px 24px;
}

.CreatorToolPromptTabPromptLabel {
    margin: 6px 0;
    font-family: var(--font-family);
    font-size: 16px;
}

.CreatorToolPromptTabPlaygroundButton {
    margin-top: 12px;
}