.PlaygroundResults {
}

.PlaygroundResult {
    position: relative;
    margin-bottom: 12px;
    padding: 24px;
    background-color: #ffffff;
    border: var(--color-light-grey) 1px solid;
    border-radius: 12px;
    font-family: var(--font-family);
}

.PlaygroundResult:hover {
    border: var(--color-purple) 1px solid;
}

.PlaygroundResultAttributesTitle {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-family);
}

.PlaygroundResultAttributesValue {
    margin-bottom: 8px;
    font-size: 12px;
    font-family: var(--font-family);
    color: #989898FF;
}

.PlaygroundResultAttributesButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-dark-grey);
}

.PlaygroundResultsResultText {
    font-family: var(--font-family);
}

.PlaygroundResultsResultTextInfo {
    font-size: 12px;
    font-family: var(--font-family);
    color: #989898FF;
    margin-top: 12px;
}

.PlaygroundResultsResultMenu {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 12px;
    right: 18px;
    color: var(--color-light-grey);
}

.PlaygroundResultsResultPromptHighlight {
    background-color: var(--color-purple);
    color: white;
    padding: 0 4px;
    border-radius: 2px;
    margin: 1px 0;
}