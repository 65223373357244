.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: var(--background-color);
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .LogoutButton {
    display: none;
  }
}

.AppLayout {
  height: 100vh;
}

.AppSider {
  background-color: #fff !important;
  text-align: center;
  color: black;
  line-height: 120px;
  box-shadow: 5px 0 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.AppSider2 {
  background-color: #fff !important;
  text-align: center;
  color: black;
  line-height: 120px;
  box-shadow: 5px 0 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

@media (max-width: 768px) {
  .react-query-devtools-panel {
    display: none !important;
  }
}