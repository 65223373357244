.ToolContainer {
    height: calc(100vh - 66px);
}

.ToolParamsContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 66px);
}

.ToolTabsContainer {
    height: calc(100vh - 66px);
}

.ToolNameContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ToolName {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-dark);
    text-align: center;
    font-family: var(--font-family);
    margin-left: 12px;
}

.ToolNameMobile {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-dark);
    text-align: left;
    font-family: var(--font-family);
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.ToolForm {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 24px 36px 0 36px;
    margin-bottom: 12px;
}

.ToolForm .ant-form-item {
    margin-bottom: 16px;
}

.ToolGenerateSection {
    padding: 12px 16px;
}

.ToolFormContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    background-color: white;
    border-right: var(--color-border) 1px solid;
    overflow-y: auto;
}

.ToolFormComponents {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 6px;
}

.ToolResultsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 72px 24px 48px;
    overflow-y: auto;
    gap: 24px;
}

.ToolGenerateSection {
    width: 100%;
    gap: 12px;
}

.ToolGenerateButton, .ToolGenerateButton .ant-btn {
    font-family: var(--font-family);
    border-radius: 24px !important;
    width: 100% !important;
}

.ToolGenerateButton .ant-btn-loading-icon {
    color: var(--color-pink-2) !important;
}

.ToolGenerateSection .ant-col .ant-form-item {
    margin-bottom: 0 !important;
}

.ToolResultsField {
    width: 150px;
}

.ToolEmptyResults {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ToolEmptyResultsImage {
    width: 150px;
    margin-bottom: 32px;
}

.ToolEmptyResultsContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    text-align: center;
}

.ToolOutputsLabel {
    font-size: 18px;
    color: var(--color-dark);
}

.ToolTitleField {
    color: var(--color-dark);
    margin-right: 4px;
    margin-bottom: 2px;
    font-size: 16px !important;
}

.ToolTitleFieldExplanation {
    color: var(--color-dark-grey);
}

.ToolTitleFieldExplanation:hover {
    color: var(--color-purple);
    cursor: pointer;
    transition: color 0.3s ease;
}

.ToolClearButton {
    inset-block-end: 24px;
}

.ToolClearResultsConfirmModalMessageContainer {
    display: flex;
    align-items: center;
    gap: 12px;
}

.ToolClearResultsConfirmModalMessageIcon {
    font-size: 34px;
    color: var(--color-purple);
}

.ToolClearResultsConfirmModalMessage {
    font-size: 16px;
}

.ToolFormCounterProgressBar {
    display: flex !important;
}

.ToolFormCounterProgressBar .ant-progress-outer {
    flex: 1;
}

.ToolFormCounterProgressBar .ant-progress-text {
    align-self: flex-start;
    width: auto;
    margin-top: 6px;
}

.ToolFormCounterProgressBar .ant-progress-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    text-align: right;
}

.ant-progress.ant-progress-status-success .ant-progress-text {
    color: var(--color-purple) !important;
}

.ToolStopGenerationButton {
    border-radius: 50px !important;
    width: 250px;
    position: absolute;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 24px;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.ToolFormComponents .ant-select .ant-select-selector {
    padding: 0 15px !important;
}

.ToolBackButton {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 5;
}

.ToolDrawer {
    color: var(--color-primary);
}

.ToolDrawerContent b, strong, h1, h2, h3, h4, h5 {
    color:rgb(94, 184, 236);
}

.ToolFloatingSwitchContainer {
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ToolFloatingSwitch {
    border-radius: 25px !important;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ToolFloatingSwitch .p-button {
    border-radius: 25px !important;
    border: none !important;
}

.ToolFloatingSwitch .p-button:nth-child(1) {
    margin-right: -12px !important;
}

.ToolFloatingSwitch .p-button.p-highlight {
    z-index: 99;
    background: var(--color-purple) !important;
}

.ToolFloatingSwitch .p-focus.p-highlight {
    box-shadow: none !important;
}

.ToolFloatingSwitch .p-button .p-button-label {
    font-family: var(--font-family) !important;
    font-weight: unset !important;
}

.ToolIntroductionContainer {
    background-color: white;
    margin: 48px 96px;
    border-radius: 12px;
    padding: 24px;
    /*height: 100%;*/
    max-height: calc(100vh - 220px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    overflow: auto;
}

.ToolIntroductionWrapper {
    position: relative;
    max-height: calc(100vh - 270px);
    padding: 24px;
}

.ToolIntroduction {
    max-height: calc(100vh - 316px);
    overflow: auto;
    border-radius: 12px;
}

.ToolIntroductionTitle {
    font-size: 22px;
    margin-bottom: 12px;
    font-family: var(--font-family);
    color: var(--color-primary-text);
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-shrink: 1;
}

.ToolIntroductionExample {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.ToolIntroductionText {
    font-size: 16px;
    font-family: var(--font-family);
    background-color: var(--color-primary-bg-hover);
    border-radius: 12px;
    padding: 24px;
    color: var(--color-primary-text-active);
}

.ToolIntroductionTags {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
    height: 50px;
}

.ToolIntroductionTag {
    background-color: var(--color-primary-bg-hover);
    font-family: var(--font-family);
    padding: 12px 24px;
    border-radius: 12px;
    font-size: 16px;
    color: var(--color-primary-text-active);
}

.ToolIntroductionTagIcon {
    margin-right: 12px;
}

.ToolIntroductionHeader {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .ToolForm {
        padding: 24px 36px 22px 36px;
    }

    .ToolResultsContainer {
        padding: 24px 24px 24px 24px;
    }
}

.ToolIntroduction::-webkit-scrollbar {
    display: block;
    width: 5px;
}
.ToolIntroduction::-webkit-scrollbar-track {
    background: transparent;
}

.ToolIntroduction::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-bg-active);
    border-right: none;
    border-left: none;
}

.ToolIntroduction::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 16px;
}

.ToolIntroduction::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 16px;
}

.p-tabview .p-tabview-panels {
    background-color: transparent !important;
}

.ToolTabsContainer .p-tabview {
    height: 100%;
}

.ToolTabsContainer .p-tabview .p-tabview-panels {
    height: calc(100vh - 170px);
}

@media (max-width: 1520px) {
    .ToolIntroductionTag {
        padding: 6px 12px;
        font-size: 14px;
    }

    .ToolIntroductionTitle {
        font-size: 20px;
    }

    .ToolIntroductionText {
        font-size: 14px;
    }

    .ToolIntroductionWrapper {
        padding: 12px;
    }

    .ToolIntroductionContainer {
        padding: 12px;
        margin: 48px;
    }
}

@media (max-width: 1200px) {
    .ToolGenerateSection {
        padding: 6px 16px;
    }

    .ToolContainer {
        height: calc(100vh - 140px);
    }

    .ToolParamsContainer {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 140px);
    }

    .ToolTabsContainer {
        height: calc(100vh - 140px);
    }
}