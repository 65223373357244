/* src/components/message/Message.css */

/* Container da mensagem */
.MessageBox {
    margin: 0 6px;
    padding: 0 6px;
    max-width: 80%;
    display: table;
    border-radius: 16px;
    font-family: var(--font-family);
    font-size: 16px;
    /* A animação permanece inalterada */
    animation: messageAppear 0.3s ease-out;
  }
  
  /* Thumbnail das imagens */
  .MessageImageThumbnail {
    max-width: 250px;
    max-height: 250px;
    cursor: pointer;
    border-radius: 4px;
    margin: 4px;
  }
  
  /* Área de arquivos anexados */
  .MessageFiles {
    margin-top: 7px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  /* Itens de arquivo */
  .MessageFileItem {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background-color: #f0f2f5;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    position: relative;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    max-width: 260px;
    width: 100%;
    overflow: hidden;
  }
  
  .MessageFileItem:hover {
    background-color: #e6f7ff;
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .MessageFileIcon {
    margin-right: 12px;
    font-size: 24px;
    color: #1890ff;
    flex-shrink: 0;
  }
  
  .MessageFileName {
    font-size: 14px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    margin-right: 8px;
  }
  
  .MessageFileActionIcon {
    font-size: 16px;
    color: #8c8c8c;
    transition: color 0.3s ease, transform 0.2s ease;
    flex-shrink: 0;
  }
  
  .MessageFileActionIcon:hover {
    color: #1890ff;
    transform: scale(1.2);
  }
  
  /* Título e timestamp */
  .MessageTitle {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 16px;
  }
  
  .MessageTimestamp {
    font-family: var(--font-family);
    font-size: 12px;
    color: #6f6f6f;
    padding-left: 8px;
  }
  
  /* Conteúdo da mensagem */
  .MessageText {
    text-align: left;
    font-size: 16px;
    font-family: var(--font-family);
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    /* Aumentando a altura da linha para melhorar a legibilidade */
    line-height: 1.8;
    letter-spacing: 0.2px;
  }
  
  /* Ajuste nos parágrafos para separar melhor o conteúdo */
  .MessageText p {
    margin: 0 0 20px; /* Margem inferior aumentada para separar os parágrafos */
    line-height: 1.8;
  }
  
  /* Hierarquia dos cabeçalhos dentro do texto */
  .MessageText h1,
  .MessageText h2,
  .MessageText h3,
  .MessageText h4,
  .MessageText h5,
  .MessageText h6 {
    margin-top: 20px;  /* Espaço acima dos títulos */
    margin-bottom: 12px; /* Espaço abaixo dos títulos */
    line-height: 1.3;
    font-weight: 600;
    /* As cores dos cabeçalhos permanecem conforme definido originalmente */
  }
  
  h1 { 
    font-size: 1.5rem; 
    letter-spacing: -0.02em;
  }
  h2 { 
    font-size: 1.3rem;
    letter-spacing: -0.01em;
  }
  h3 { 
    font-size: 1.2rem; 
  }
  h4 { 
    font-size: 1.1rem; 
  }
  h5 { 
    font-size: 1.05rem; 
  }
  h6 { 
    font-size: 1rem; 
  }
  
  /* Blocos de código (Markdown) – foco na tipografia e espaçamento */
  .MessageText pre {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 12px 16px; /* Aumentei o padding para facilitar a leitura do código */
    overflow-x: auto;
    margin: 16px 0;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
  }
  
  .MessageText code {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 0.2em 0.4em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 85%;
  }
  
  /* Listas – ajuste no espaçamento dos itens para melhor leitura */
  .MessageText ul,
  .MessageText ol {
    padding-left: 12px;
    margin: 3px 0;
  }
  
  .MessageText li {
    margin: 2px 0;
    line-height: 1.4;
  }
  
  /* Mensagens enviadas pelo usuário */
  .MessageUser {
    float: right;
    background-color: #f0f2f5;
    color: #000000;
    padding: 12px 16px;
  }
  
  /* Mensagens do sistema */
  .MessageSystem {
    float: left;
    background-image: linear-gradient(135deg, #444444, #555555);
    color: #ffffff;
    padding: 12px 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  /* Overrides para Markdown em mensagens do sistema para manter a legibilidade do texto */
  /* Blocos de código */
  .MessageSystem .MessageText pre {
    background-color: #333333 !important;
    color: #ffffff !important;
  }
  
  /* Trechos de código inline */
  .MessageSystem .MessageText code {
    background-color: #333333 !important;
    color: #ffffff !important;
  }
  
  /* Cabeçalhos em mensagens do sistema */
  .MessageSystem .MessageText h1,
  .MessageSystem .MessageText h2,
  .MessageSystem .MessageText h3,
  .MessageSystem .MessageText h4,
  .MessageSystem .MessageText h5,
  .MessageSystem .MessageText h6 {
    color: #f0f0f0 !important;
  }
  
  /* Ícone da mensagem */
  .MessageIcon {
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    height: 40px;
    width: 40px;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .left {
    float: left;
  }
  
  .right {
    float: right;
  }
  
  /* Responsividade – ajustes na tipografia para telas menores */
  @media (max-width: 748px) {
    .MessageBox {
      max-width: 100%;
      padding: 8px 6px;
      display: flex;
      flex-direction: column;
    }
  
    .MessageText {
      font-size: 14px;
    }
  
    .MessageTitle {
      font-size: 14px;
    }
  
    .MessageTimestamp {
      font-size: 10px;
    }
  
    .MessageIcon {
      height: 32px;
      width: 32px;
      font-size: 18px;
    }
  
    .MessageFiles {
      gap: 8px;
      flex-direction: column;
    }
  
    .MessageFileItem {
      padding: 8px 12px;
      max-width: 100%;
      width: 100%;
    }
  
    .MessageFileIcon {
      font-size: 20px;
      margin-right: 8px;
      margin-top: 2px;
    }
  
    .MessageFileName {
      font-size: 13px;
    }
  
    .MessageFileActionIcon {
      font-size: 14px;
    }
  
    .MessageFileItem:hover {
      transform: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }
  
    .MessageImageThumbnail {
      max-width: 100%;
      height: auto;
    }
  }
  
  /* Sombra padrão para mensagens */
  .MessageSystem,
  .MessageUser {
    box-shadow: var(--default-box-shadow);
  }
  
  /* Animação de entrada */
  @keyframes messageAppear {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  