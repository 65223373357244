/* Fundo gradiente clarinho, estilo MainMenu */
.ChatHistorySider {
    background: linear-gradient(to bottom, #f9f7ff, #f3e8ff) !important;
    border-right: 1px solid #3b60aa;
    overflow: hidden;
    transition: all 0.3s ease;
  
    /* Borda quadrada */
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  
    /* Removendo sombra superior */
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
  }
  
  /* 1) Regra geral: TUDO em branco */
  .ChatHistorySider * {
    color: #fff !important;
  }

  .ChatHistorySider button.CarregarMaisBtn,
  .ChatHistorySider .CarregarMaisBtn * {
    color: #000 !important;
  }

  .CarregarMaisBtn {
    background: transparent;
    cursor: pointer;
  }
  
  /* 2) Exceções em preto */
  .ChatHistoryItemTitle,
  .ChatHistoryItemTitle * {
    color: #000 !important;
  }
  
  .ChatHistoryCollapseButton,
  .ChatHistoryCollapseButton * {
    color: #000 !important;
  }
  
  /* Quando colapsado, a largura vira 80px */
  .ChatHistorySider.collapsed {
    width: 80px !important;
  }
  
  /* Deixa o interior do Sider como flex */
  .ChatHistorySider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  /* Ocultar botão "Novo chat" quando colapsado */
  .ChatHistorySider.collapsed .PremiumButton {
    display: none;
  }
  
  /* Ajuste ao colapsar */
  .ChatHistorySider.collapsed .ChatHistoryItem {
    justify-content: center;
  }
  .ChatHistorySider.collapsed .ChatHistoryItemTitleContainer {
    display: none;
  }
  
  /* Scroll customizado (oculta barra) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE/Edge */
    scrollbar-width: none;     /* Firefox */
  }
  
  /* Estilos "legados", se ainda precisar */
  .ChatHistoryContainer {
    position: relative;
    overflow-y: scroll;
    margin: 24px 0;
    height: 85%;
  }
  
  .ChatHistoryOpen {
    position: absolute;
    transition: all 0.3s;
  }
  
  .ChatHistoryButton {
    width: 100%;
    border-radius: 16px;
    left: 0;
    bottom: 0;
    font-family: var(--font-family);
    border: 1px solid black;
  }
  
  .ChatHistoryButton:hover {
    color: white !important;
    background-color: black !important;
  }
  
  .ChatHistoryButtonContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .btn-chat {
    background: linear-gradient(0.7turn, #2194C1, #5A539F);
    color: white;
    transition: background 0.3s ease;
  }
  .btn-chat:hover {
    background: linear-gradient(0.7turn, #197a9f, #48418a);
  }
  
  @media (max-width: 748px) {
    .ChatHistory {
      width: 200px !important;
      padding: 6px;
    }
  
    .ant-layout-sider {
      min-width: 52px !important;
    }
  }
  
  /* Trigger colapsado do antd */
  .ant-layout-sider-trigger {
    width: auto !important;
    background-color: transparent !important;
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
  }
  