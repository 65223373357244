.SettingsSideMenu {
    padding: 132px 8px 8px 8px;
    height: 100%;
}

.SettingsOutsideContainer {
    padding: 36px;
    display: grid;
}

.SettingsInsideContainer {
    height: 100%;
    background: white;
    padding: 36px;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.05) 0 3px 12px;
}

.SettingsField {
    margin-bottom: 24px;
    width: 100%;
    display: grid;
    gap: 6px;
}

.SettingsFieldTitle {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-dark);
}

.SettingsSectionTitle {
    font-size: 18px;
    font-family: var(--font-family);
    color: var(--color-purple);
    font-weight: bold;
    margin-bottom: 8px;
}

.SettingsProfilePictureUploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 80px;
}

.SettingsProfilePicture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SettingsSaveButton {
    display: flex;
    justify-content: flex-end;
}

.SettingsDivider {
    width: 100%;
    margin: 48px 0;
    /*height: 1px;*/
    /*background: var(--color-light-grey);*/
}

.SettingsHeaderTitle {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-dark);
    text-align: center;
    font-family: var(--font-family);
    margin-left: 12px;
}

.SettingsSideMenu .ant-menu {
    border-inline-end: unset !important;
}

.SettingsVerificationCodeContainer {
    display: flex;
    margin: 12px 0;
}

.SettingsVerificationCodeInput {
    flex-grow: 1;
    margin-right: 12px;
}

.SettingsVerificationCodeText {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-dark-grey);
}

.SettingsValidationText {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-dark-grey);
}

.SettingsProfilePictureBackground {
    background-color: var(--color-primary-bg);
}

.SettingsSideMenuFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px;
}