/* Variáveis globais */
:root {
    --background-color: #fff;
    --icon-color: #aaa;
    --icon-hover-color: #1890ff;
    --overlay-background: rgba(0, 0, 0, 0.6);
    --text-color: #fff;
    --prompt-text-color: #e0e0e0;
    --modal-background: rgba(0, 0, 0, 0.85);
    --grid-gap: 24px;
    --font-family: 'Arial', sans-serif;
}

/* Estilo geral */
.CommunityGallery {
    padding: 20px;
    background-color: var(--background-color);
    margin: 0 auto;
    font-family: var(--font-family);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
    font-weight: 600;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--grid-gap);
}

.gallery-item {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
    cursor: pointer;
}

.gallery-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 12px;
}

.icon-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    background-color: var(--overlay-background);
    padding: 6px;
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gallery-item:hover .icon-container {
    opacity: 1;
}

.icon {
    font-size: 20px;
    margin: 0 6px;
    cursor: pointer;
    color: var(--icon-color);
    transition: color 0.3s ease;
}

.icon:hover {
    color: var(--icon-hover-color);
}

.loading-container,
.error-container,
.no-images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    color: #888;
    font-size: 18px;
}

/* Responsividade */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .icon {
        font-size: 18px;
    }

    .gallery-item {
        border-radius: 8px;
    }
}