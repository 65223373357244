/* src\components\menu\MainMenu.css */

.MainMenu {
    /* Base: Ocupa 100% da altura do contêiner (pode ser ajustado via media query) */
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow-y: auto;
    background-color: #fff; /* se quiser garantir fundo branco */
    /* Se preferir, pode remover esta cor e usar var(--...) */
  }
  
  .MainMenuLogo {
    height: 32px;
    margin: 48px auto;
    transition: opacity 0.2s ease-in-out;
  }
  
  .MainMenuLogo:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  
  .MainMenuDivider {
    height: 4px;
    width: 25px;
    background-color: var(--color-dark-grey);
    border-radius: 24px;
    margin: 0 auto 48px;
  }
  
  .MainMenuButton {
    border-radius: 24px !important;
    margin-bottom: 16px;
  }
  
  .MainMenuButtons .ant-ribbon-wrapper .ant-ribbon {
    top: -5px !important;
  }
  
  .MainMenuEndSection {
    width: 100%;
    padding: 8px;
    margin-top: auto;
  }
  
  .MainMenu .ant-menu {
    border-inline-end: unset !important; /* Remove borda do ant-menu */
  }
  
  /* Itens do menu alinhados à esquerda */
  .MainMenuItem {
    text-align: left;
  }
  
  /* Ícone menor e com espaçamento */
  .MainMenuItemIcon {
    width: 20px;
    margin-right: 8px;
  }
  
  .MainMenuItemIcon path {
    text-align: left !important;
  }
  
  /* Título do menu */
  .MainMenuTitle {
    margin: 24px 0 12px;
    font-size: 18px;
    color: var(--color-primary-text);
    text-align: center;
    font-family: var(--font-family);
  }
  
  /* ---------------------- */
  /*       VERSÃO DESKTOP   */
  /*   (telas acima de 768) */
  /* ---------------------- */
  @media (min-width: 769px) {
    .MainMenu {
      /* Largura típica de um menu lateral fixo no desktop */
      width: 220px;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh; /* Ocupa a altura total da tela */
      overflow-y: auto;
      /* Se desejar, pode adicionar:
         border-right: 1px solid #ddd;
         para destacar a borda */
    }
  }
  
  /* ---------------------- */
  /*       VERSÃO MOBILE    */
  /*   (telas até 768px)    */
  /* ---------------------- */
  @media (max-width: 768px) {
    .MainMenuLogo {
      margin: 24px auto;
    }
  
    .MainMenuDivider {
      margin-bottom: 24px;
    }
  
    .MainMenuEndSection {
      position: relative;
      bottom: unset;
      padding: 16px;
    }
  
    .MainMenu {
      /* Em mobile, o menu pode ficar fluido dentro do layout */
      position: relative;
      width: 100%;
      height: auto; /* Cresce conforme o conteúdo */
    }
  
    .MainMenu .ant-menu {
      overflow-x: hidden;
    }
  
    /* Ajustar espaçamento do ícone */
    .MainMenuItemIcon {
      margin-right: 12px;
    }
  
    /* Aumentar padding lateral dos itens do menu do antd */
    .MainMenu .ant-menu-item,
    .MainMenu .ant-menu-submenu-title {
      padding-left: 24px !important;
    }
  
    /* Ajuste do tamanho da fonte no mobile */
    .MainMenu .ant-menu-item > .ant-menu-title-content,
    .MainMenu .ant-menu-submenu-title > .ant-menu-title-content {
      font-size: 14px;
    }
  }
  