.MainHeaderTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.MainHeaderTitleLabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    font-family: var(--font-family);
    font-size: 15px;
    color: var(--color-primary-text);
    background: var(--color-primary-bg);
    border-radius: 8px;
    padding: 0 18px;
    height: 42px;
}

.MainHeaderTitleBackIcon {
    color: var(--color-primary-text);
}

@media (max-width: 768px) {
    .MainHeaderTitleLabel {
        max-width: 250px;
    }

    .MainHeaderTitleLabelTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}