.ToolsFilter {
    padding: 12px 36px;
}

.ToolsFilterMobile {
    padding: 12px 24px;
}

.ToolsFilterSearchBox {
    border-radius: 24px;
}

.ToolsFilterSearchBoxCollapsed {
    color: var(--color-dark);
    text-align: center;
    font-size: 18px;
}

.ToolsFilterSearchBox .ant-input-affix-wrapper {
    border-radius: 24px;
}

.ToolsFilterSearchBox .ant-input-affix-wrapper .ant-input-prefix{
    color: var(--color-light-grey);
    margin-right: 8px;
}

.ToolsFilterSearchBox .ant-input-affix-wrapper .ant-input {
    font-weight: bold;
    color: var(--color-primary-text);
}

.ToolsFilterTag {
    border-radius: 24px;
    font-family: var(--font-family);
    font-size: 14px;
    margin: 2px;
}

.ToolsFilterTagsContainer {
    width: 100%;
    margin-top: 12px;
}

.ToolsFilterTags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.ToolsFilterClearFiltersButtonContainer {
    display: flex;
    justify-content: center;
}

.ToolsFilterClearFiltersButton {
    border-radius: 25px;
}

.ToolsFilter .ant-layout-sider-trigger {
    right: unset !important;
    left: 5px;
}

.ToolsFilterCategoryButton {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 6px 12px 6px 8px;
    border: var(--color-primary-border) 1px solid;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: var(--color-primary-bg);
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    color: var(--color-primary-text-active);
}

.ToolsFilterCategoryButton:hover {
    background-color: var(--color-primary-bg-hover);
    border: var(--color-primary-border-hover) 1px solid;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    cursor: pointer;
    color: var(--color-primary-text-hover);
    font-weight: bold;
}

.ToolsFilterCategoryButtonSelected {
    background-color: var(--color-primary-bg-active);
    border: var(--color-primary-border-hover) 1px solid;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    box-shadow: 0 4px 6px rgba(114, 76, 249, 0.1), 0 1px 3px rgba(114, 76, 249, 0.1);
    color: var(--color-primary-text-active);
    font-weight: bold;
}

@media (max-width: 768px) {
    .ToolsFilterCategoryButton {
        padding: 4px 8px 4px 6px;
        font-size: 13px;
    }

    .ToolsFilterTags {
        display: flex;
        justify-content: center;
    }
}