.Login {
    font-family: var(--font-family);

}

.LoginLogo {
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #1D1C1D;
}

@media (max-width: 1200px) {
    .LoginLogoImage {
        height: 80px !important;
    }
}

@media (max-width: 1200px) {
    .LoginLogoImage {
        height: 60px !important;
    }
}

@media (max-width: 850px) {
    .LoginLogoImage {
        height: 40px !important;
    }
}

[data-amplify-authenticator] {
    --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
    --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
    --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
    --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
    --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);

    --amplify-components-alert-background-color: green !important;

    --amplify-components-button-primary-background-color: var(--color-green);
    --amplify-components-button-primary-hover-background-color: var(--color-purple);

    --amplify-components-fieldcontrol-border-color: var(--color-green) !important;
    --amplify-components-fieldcontrol-focus-border-color: var(--color-purple);
    --amplify-components-fieldcontrol-focus-box-shadow: var(--color-purple);
    --amplify-components-fieldcontrol-font-size: 8px;
    --amplify-components-textfield-border-color: var(--color-green) !important;

    --amplify-components-tabs-item-active-color: var(--color-purple);
    --amplify-components-tabs-item-active-border-color: var(--color-purple);

    --amplify-components-tabs-item-color: var(--color-dark-grey);
    --amplify-components-tabs-item-hover-color: var(--color-purple);
    --amplify-components-tabs-item-focus-color: var(--color-purple);

    --amplify-components-button-border-color: var(--color-light-grey);
    --amplify-components-text-color: var(--color-dark-grey);

    --amplify-components-textfield-focus-border-color: var(--color-purple);

    --amplify-components-button-focus-border-color: var(--color-purple);
    --amplify-components-button-focus-box-shadow: var(--color-purple);

    --amplify-components-authenticator-form-padding: 32px 32px 16px 32px;


}

[data-amplify-authenticator] [data-amplify-router] {
    box-shadow: var(--amplify-components-authenticator-router-box-shadow);
    border-color: transparent;
    border-width: 0;
}