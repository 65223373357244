.SubscriptionPricingTable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.SubscriptionPricingGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    gap: 24px;
    justify-content: center;
}

.SubscriptionPricingColumnHighlight {
    background-color: var(--color-primary-bg) !important;
    border: var(--color-primary-border) 2px solid;
    /*height: 105% !important;*/
    /*transform: translateY(-2.5%);*/
}

.SubscriptionPricingColumn {
    height: 100%;
    width: 300px;
    border-radius: 24px;
    /*border: var(--color-primary-border) 1px solid;*/
    box-shadow: var(--default-box-shadow);
    padding: 8px 24px 8px 24px;
    position: relative;
    background-color: white;
}

.SubscriptionPricingColumn:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.SubscriptionPricingColumnImage {
    text-align: center;
}

.SubscriptionPricingColumnTitle {
    font-size: 24px;
    font-family: var(--font-family);
    color: var(--color-dark);
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center;
}

.SubscriptionPricingColumnMonthlyPriceContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 4px;
    justify-content: center;
}

.SubscriptionPricingColumnPrice {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: var(--color-dark);

    /*background-color: var(--color-primary-bg);*/
    /*border-radius: 24px;*/
    /*padding: 12px 0;*/
    /*margin: 12px 0;*/
    /*color: var(--color-primary-text-hover);*/
}

.SubscriptionPricingColumnPriceDivider {
    height: 1px;
    width: 25%;
    display: flex;
    align-self: center;
    justify-content: center;
    background-color: var(--color-primary-text-hover);
    margin: 12px 0;
}

.SubscriptionPricingColumnLTDPrice {
    font-size: 46px;
    font-family: var(--font-family);
    color: var(--color-primary-text);
    font-weight: bold;
}

.SubscriptionPricingColumnMonthlyPrice {
    font-size: 36px;
    font-family: var(--font-family);
    color: var(--color-primary-text);
    font-weight: bold;
}

.SubscriptionPricingColumnMonthlyPriceBillingLabel {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-primary-text)
    /*color: var(--color-dark);*/
}

.SubscriptionPricingColumnMonthlyPriceAppendix {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-primary-text);
    /*color: var(--color-dark);*/
    margin-bottom: 5px;
}

.SubscriptionPricingColumnDailyPrice {
    font-size: 14px;
    font-family: var(--font-family);
    margin: 8px 0;
    background-color: var(--color-primary-bg);
    border-radius: 24px;
    text-align: center;
    padding: 8px 0;
    color: var(--color-primary-text-hover);
}

.SubscriptionPricingColumnFeatures {
    margin-top: 12px;
    margin-bottom: 6px;
}

.SubscriptionPricingColumnButton {
    margin: 6px 0;
}

.SubscriptionPricingColumnFeature {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 4px 0;
    padding-left: 12px;
}

.SubscriptionPricingColumnFeatureIcon {
    color: var(--color-primary-border-hover);
    font-size: 18px;
}

.SubscriptionPricingColumnFeatureLabel {
    font-family: var(--font-family);
    color: var(--color-primary-text-active);
    font-size: 14px;
}

.SubscriptionPricingColumnTag {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: var(--font-family);
    padding: 6px 12px;
    border-radius: 24px;
    /*font-weight: bold;*/
    /*border: var(--color-primary-border) 1px solid;*/
    /*box-shadow: rgba(0, 0, 0, 0.05) 0 3px 12px;*/
}

.SubscriptionPricingColumnTagTrial {
    background-color: var(--color-secondary);
}

.SubscriptionPricingTableBillingCycle {
    border-radius: 25px !important;
    /*background-color: white;*/
    box-shadow: var(--default-box-shadow);
    /*border: var(--color-primary-border) 1px solid;*/
    background: white;
    z-index: 99;
}

.SubscriptionPricingTableBillingCycle .p-button {
    border-radius: 25px !important;
    border: none !important;
}

.SubscriptionPricingTableBillingCycle .p-button:focus {
    box-shadow: unset !important;
}

.SubscriptionPricingTableBillingCycle .p-button.p-highlight {
    z-index: 99;
    background-image: linear-gradient(135deg, #62afd9 -10.67%, #5437d4 42.73%, #bf4cf9 110%) !important;
}


.SubscriptionPricingTableBillingCycleLabel {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-primary-text-active);
    font-weight: bold;
    margin-top: 8px;
    z-index: 10;
}

@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.shimmer-effect {
    background: linear-gradient(-45deg, var(--color-primary) 10%, var(--color-primary-active) 50%, var(--color-primary) 90%);
    background-size: 200px 100%;
    animation: shimmer 2s infinite;
}

@media (max-width: 1588px) {
    .SubscriptionPricingGrid {
        grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
        gap: 0;
    }

    .SubscriptionPricingColumn {
        padding: 8px 12px 8px 12px;
        width: 260px;
    }
}

@media (max-width: 1360px) {
    .SubscriptionPricingTable {
        align-items: unset;
        height: unset;
        margin: 48px 0;
    }
}

@media (max-width: 1720px) {
    .SubscriptionPricingColumnButton {
        margin: 6px 0;
    }

    .SubscriptionPricingColumnFeatures {
        margin-top: 6px;
    }

    .SubscriptionPricingColumnTitle {
        margin-bottom: 0;
    }

    .SubscriptionPricingColumn {
        padding: 16px;
    }
}

.SubscriptionPricingColumnDailyPrice p {
    margin: 0;
}

.SubscriptionPricingTableBillingCycleArrow {
    width: 32px;
    transform: rotate(260deg);
    z-index: 10;
}

.SubscriptionPricingTableBillingCycleArrow path {
    fill: var(--color-dark-grey);
}

.SubscriptionPricingTableTrialWarning {
    position: absolute;
    left: 40px;
    top: 24px;
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--color-dark-grey);
}