.StopGenerationButton {
    border-radius: 50px !important;
    width: 250px;
    position: absolute;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.StopGenerationButton.visible {
    opacity: 1;
}

.isDesktop {
    position: absolute;
}

.isMobile {
    position: fixed;
}