/* src/components/Artifact/ArtifactViewer.css */

.artifact-modal {
    width: 80%;
    height: 80vh;
  }
  
  .artifact-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  @media (max-width: 768px) {
    .artifact-modal {
      width: 95%;
      height: 90vh;
    }
  }
  