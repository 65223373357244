/* src/components/chat/PromptSuggestions.css */

.prompt-suggestions {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    padding: 1rem;
  }
  
  .prompt-button {
    position: relative;
    background: linear-gradient(135deg, #7947c4, #000000); /* Tons de roxo escuro */
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0.85rem 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    /* Sombra mais marcante para realce */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    outline: none;
    overflow: hidden;
  }
  
  /* Pseudo-elemento para criar um efeito glossy aprimorado */
  .prompt-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.1) 30%,
      transparent 70%
    );
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .prompt-button:hover {
    /* Inverte levemente o gradiente para um efeito interativo */
    background: linear-gradient(135deg, #46217c, #2e0e5d);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }
  
  .prompt-button:hover::after {
    opacity: 1;
  }
  
  .prompt-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  }
  
  /* Responsividade: em telas menores, os botões ocupam a largura total */
  @media (max-width: 768px) {
    .prompt-suggestions {
      grid-template-columns: 1fr;
    }
  }
  