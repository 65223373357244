.WalletBalanceWidget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: var(--font-family);
}

.WalletBalanceWidgetTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-primary-text-active);
    display: flex;
    align-items: center;
}

.WalletBalanceWidgetSubtitle {
    font-size: 14px;
    color: var(--color-dark-grey);
}

.WalletBalanceWidgetTooltipText {
    color: white;
}

.WalletBalanceWidgetSuffix {
    font-size: 14px;
    color: var(--color-primary-text-active);
    margin-left: 4px;
}

.WalletBalanceWidgetOrganizationName {
    font-size: 14px;
    color: var(--color-primary-text-active);
    font-family: var(--font-family);
    font-weight: bold;
}

.WalletBalanceWidgetUpgradeContainer {
    width: 100%;
}