.ImageGenerator {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ImageGeneratorPromptWrapper {
    display: grid;
    align-items: center;
    flex-grow: 1;
}

.ImageGeneratorPrompt {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.ImageGeneratorPromptContainer {
    width: 60%;
    position: relative;
}

.ImageGeneratorPromptGenerateButton {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 99;
}

.ImageGeneratorPromptToolbar {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.ImageGeneratorCarouselImage, .ImageGeneratorCarouselImage + .ant-image-mask {
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

.ImageGeneratorCarouselImageContainer {
    position: relative;
    padding: 12px;
}

.ImageGeneratorCarouselContainer {
    margin: 24px;
    padding: 24px;
    text-align: center;
}

.ImageGeneratorCarouselImageMenu .ant-btn {
    top: 24px !important;
    right: 24px !important;
}

.ImageGeneratorCarouselImageContainer:hover .ImageGeneratorCarouselImageMenu {
    visibility: visible;
}

.ImageGeneratorCarouselImageMenu {
    visibility: hidden;
}

.ImageGeneratorPromptInput {
    background: white;
    box-shadow: var(--default-box-shadow);
    border-radius: 12px;
    padding: 12px;
}

.ImageGeneratorPromptInput, .ImageGeneratorPromptInput:hover, .ImageGeneratorPromptInput:active, .ImageGeneratorPromptInput:focus {
    background: white;
    box-shadow: var(--default-box-shadow);
    border-radius: 12px;
    padding: 12px 120px 12px 12px !important;
}

.ImageGeneratorPromptSparklesIcon {
    margin-right: 8px;
}

.ImageGeneratorExampleContainer {
    margin: 24px;
    padding: 24px;
    display: flex;
    height: 560px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ImageGeneratorExample {
    font-family: var(--font-family);
    font-size: 28px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
    width: 60%;
}

.ImageGeneratorImageTitle {
    font-size: 18px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 -10%, #bf4cf9 42.73%, #5437d4 110%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: var(--font-family);
    text-align: center;
    display: inline;
}

.ImageGeneratorImageTitleContainer {
    margin: 8px;
}





/* barra de progresso*/

.ImageGeneratorProgressContainer {
    margin-top: 24px;
    text-align: center;
    position: relative;
    height: 200px;
}

.progress-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ImageGeneratorProgressContainer .ant-progress {
    max-width: 400px;
    margin: 0 auto;
}

.ImageGeneratorProgressMessage {
    margin-top: 16px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
}

.ImageGeneratorProgressContainer .ant-progress-bg {
    height: 12px !important;
}

.ImageGeneratorProgressContainer .ant-progress-inner {
    border-radius: 6px;
}

.ImageGeneratorProgressContainer .ant-progress-bg {
    border-radius: 6px;
}

@media (max-width: 768px) {
    .ImageGeneratorProgressContainer {
        height: 150px;
    }

    .ImageGeneratorProgressMessage {
        font-size: 16px;
    }
}

/* fim da barra de progresso*/






@media (max-width: 720px) {
    .ImageGeneratorExampleContainer {
        margin: 12px;
        padding: 12px;
        height: 350px;
    }
    .ImageGeneratorExample {
        font-size: 20px;
        width: 90%;
    }

    .ImageGeneratorPromptInput {
        padding: 6px;
        font-size: 14px;
    }

    .ImageGeneratorPromptInput, .ImageGeneratorPromptInput:hover, .ImageGeneratorPromptInput:active, .ImageGeneratorPromptInput:focus {
        padding: 6px 100px 6px 6px !important;
    }

    .ImageGeneratorPromptContainer {
        width: 90%;
    }

    .ImageGeneratorPromptToolbar {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}