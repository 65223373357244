.CreatorToolSaveButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.CreatorToolSaveButton {
    margin: 12px 24px;
    display: flex;
    align-items: center;
}

.CreatorToolSplitterContainer {
    height: 100%;
    overflow: auto;
}

.CreatorToolNameContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreatorToolName {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-dark);
    text-align: center;
    font-family: var(--font-family);
    margin-left: 12px;
}