.PlaygroundPlaceholdersContainer {
    padding: 12px 0;
    background-color: white;
    border-right: 1px solid #e6e6e6;
    height: 100%;
    overflow: auto;
}

.PlaygroundPlaceholdersTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 12px 0;
    width: 100%;
    text-align: center;
    font-family: var(--font-family);
}

.PlaygroundPlaceholdersPlaceholder {
    margin-bottom: 12px;
}

.PlaygroundPlaceholdersPlaceholderText {
    font-family: var(--font-family);
}