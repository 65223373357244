.PlaygroundContainer {
    height: 100%;
}

.Playground {
    position: relative;
    height: calc(100vh - 66px);
    overflow: auto;
    padding: 12px 24px;
}

.PlaygroundTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 12px 0;
    width: 100%;
    text-align: left;
    font-family: var(--font-family);
}

.PlaygroundSavePromptButton {
    position: absolute;
    top: 18px;
    right: 24px;
}