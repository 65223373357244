/* Header */
.ImageGalleryComponentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Melhora o alinhamento */
    margin: 24px;
}

.ImageGalleryComponentFilterButtons {
    display: flex;
    gap: 8px; /* Espaçamento consistente entre os botões */
}

/* Grid Items */
.ImageGalleryComponentSelectableGridItem {
    transition: opacity 0.15s ease;
    cursor: pointer;
}

.ImageGalleryComponentSelectableGridItem:hover {
    opacity: 0.7;
}

.ImageGalleryGridItem {
    position: relative;
    display: inline-block;
    margin: 6px;
    border-radius: 8px; /* Reduzido o raio para um visual mais minimalista */
    overflow: hidden; /* Garante que as bordas sejam respeitadas */
}

.ImageGalleryImage {
    border-radius: 8px; /* Consistente com o item */
    width: 100%;
    height: auto;
    object-fit: cover; /* Garante que a imagem seja exibida corretamente */
}

.ImageGalleryImageMenu {
    position: absolute;
    bottom: 8px;
    right: 8px;
    visibility: hidden;
    transition: visibility 0.2s ease;
}

.ImageGalleryGridItem:hover .ImageGalleryImageMenu {
    visibility: visible;
}

/* DataView */
.ImageGallery {
    padding: 24px;
    text-align: center;
    background-color: #fff; /* Fundo claro */
    border-radius: 8px; /* Bordas suaves */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Sombra leve */
}

.ImageGallery .p-dataview-header,
.ImageGallery .p-dataview-content,
.ImageGallery .p-paginator {
    background: transparent !important;
    border: none !important;
}

.ImageGallery .p-dataview-content .grid {
    display: unset !important;
}

/* Empty State (adicione esta classe para o estado vazio) */
.ImageGalleryEmptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    text-align: center;
    color: var(--color-light-grey);
}

.ImageGalleryEmptyState .ant-empty-image {
    height: 100px; /* Tamanho adequado para o ícone */
}

.ImageGalleryEmptyState .ant-btn {
    border-radius: 8px;
    font-size: 16px;
    padding: 12px 24px;
    background-color: var(--color-primary-bg-active);
    border: none;
    color: #fff;
    transition: background-color 0.2s ease;
}

.ImageGalleryEmptyState .ant-btn:hover {
    background-color: var(--color-primary-bg-hover);
}

/* Filter Buttons */
.ImageGalleryFilterButton {
    background: transparent;
    border: 1px solid #ddd; /* Borda clara */
    border-radius: 8px; /* Reduzido o raio */
    padding: 8px 16px;
    font-size: 14px;
    color: var(--color-dark-grey);
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.ImageGalleryFilterButton:hover {
    background-color: #f9f9f9; /* Feedback ao passar o mouse */
    border-color: var(--color-primary-border-hover);
}

.ImageGalleryFilterButtonSelected {
    background-color: var(--color-primary-bg-active);
    border-color: var(--color-primary-border-hover);
    color: #dfdced;
    font-weight: bold;
}

/* Typography */
.ImageGalleryTitle {
    font-size: 24px;
    font-weight: 600;
    background: linear-gradient(135deg, #b6b2c7 -10%, #bf4cf9 42.73%, #5437d4 110%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: var(--font-family);
    margin-right: 24px;
}

.ImageGalleryListItemTitle {
    font-size: 18px;
    font-weight: 600;
    background: linear-gradient(135deg, #5437d4 0%, #bf4cf9 42.73%, #5437d4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: var(--font-family);
    text-align: left;
}

.ImageGalleryListItemPrompt {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-dark-grey);
    text-align: left;
}